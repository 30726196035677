import React, { useCallback, useEffect, useContext, useState } from 'react'
import {Row, Col,Table,Modal} from 'react-bootstrap'
import api from '../../config/axios';
import Menu from './Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Button, Stack } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { signal } from '@preact/signals'
import { useSignals } from "@preact/signals-react/runtime";
import { InputLabel, MenuItem, Select } from '@mui/material';
import { AuthenticateContext } from '../../config/AuthContext';
import {jours, creneaux} from './../../config/JourCreneauSalles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa9, faDeleteLeft, faEdit, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
//---------------signals-------
const yearSignal = signal(0);
const listPlannings = signal([]);
const uniquesNames = signal([]);
const creneau = signal("");
const jour = signal("");
const profId = signal(0);
const filierSignal = signal("");
const moduleID = signal(0);
const filieresOfProf = signal([]);
const modulesOfProf = signal([]);
const profplaning = signal({});
const sallesDispo = signal([]);
const salleSignal = signal("");
const dateAbsenceSignal = signal("");
const allRattrapages = signal([]);
const uniqDepartement = signal([]);
const uniqFiliere = signal([]);
const dateRattrapage = signal("");
//-------------------------------
function AddRattrapage() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    //--------------------modal2 added---------------
    const [show, setShow] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const handleClose = () => setShow(false);
    const [msg, setMsg] = useState("");
    const [classe, setClasse] = useState("text-info h4");
    const handleCloseDelete = () => setShowDel(false);
    const [del, setDel] = useState(0);
    const handleShowDel = (delId) => {setDel(delId); setShowDel(true); }
//--------------------------------------------------------------
    //===========================getAllPlanningsByYear=====================================
const getAllPlanningsByYear = useCallback(async (annee) => {
    console.log("im getAllPlanningsByYear im executing.....");
    let A1 = annee;
    let A2 = A1 + 1;
    const url = "api/allplannins/"+A1+"/"+A2;
      api.get(url)
      .then(rep =>{
          let lst = rep.data.plannings;
          listPlannings.value = lst;
          console.log(listPlannings.value);
          //------------------------------------
          uniquesNames.value = listPlannings.value.map(item => ({ id_professor:item.id_professor,nom_fr: item.nom_fr, prenom_fr: item.prenom_fr })) // Extraire nom_fr et prenom_fr
          .filter((value, index, self) => 
            index === self.findIndex((t) => (
              t.nom_fr === value.nom_fr && t.prenom_fr === value.prenom_fr
            )) // Garder seulement les objets uniques
          )
          .sort((a, b) => a.nom_fr.localeCompare(b.nom_fr)); // Trier par nom_fr

          console.log(uniquesNames.value);
        
      })
      .catch(err => {
         console.error(err);
      });    
  },[]);
  //===========================getAllRattrapagesByYear=====================================
const getAllRattrapagesByYear = useCallback(async (annee) => {
    console.log("im getAllPlanningsByYear im executing.....");
    let A1 = annee;
    let A2 = A1 + 1;
    const url = "api/allrattrapages/"+A1+"/"+A2;
      api.get(url)
      .then(rep =>{
          let lst = rep.data.rattrapages;
          allRattrapages.value = lst;
          //------inverser la liste ------
           let listrevers = [];
           listrevers = allRattrapages.value.reverse();
           allRattrapages.value = listrevers;
           //--------------------------------
          //-----------liste d'uniques filières & départements
          let lstdep = lst.map(d => d.libelle_departement);
          let lstfil = lst.map(d => d.libelle_filiere);
          const uniqueDep = lstdep.filter((item, index, arr) => arr.indexOf(item) === index);
          const uniqueFil = lstfil.filter((item, index, arr) => arr.indexOf(item) === index);
          uniqDepartement.value = uniqueDep;
          uniqFiliere.value = uniqueFil;
          //------------------------------------------------
         // setIndicateur(true);
          console.log("all rattrapages: ",allRattrapages.value);
      })
      .catch(err => {
         console.error(err);
      });    
  },[]);
     //---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
    console.log("im getYearUniv im executing.....");
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
        yearSignal.value = rep.data.annee[0].annee_univ;
        getAllPlanningsByYear(yearSignal.value);
        getAllRattrapagesByYear(yearSignal.value);
                      
    }).catch(err => {
      console.error(err);
    });
  },[]);
  //---------
  useEffect(() =>{
    getYearUniv();
  },[]);
  //--------------------------------salles disponibles-----
  const getSallesDisp =async () =>{
    let year1 = yearSignal.value;
    let year2 = year1+1;
    api.get('/api/sallesdispo/'+year1+'/'+year2+'/'+jour.value+'/'+creneau.value)
    .then(rep =>{
        
        console.log(rep.data.salles_disponibles);
        sallesDispo.value = rep.data.salles_disponibles;  
      }).catch(err => {
    console.error(err);
    });
}
//------------------------Ajouter le rattrapage-----------
const addRattrapage = async () => { 
    api.post('/api/rattrapage',{
      "date_rattrapage":dateRattrapage.value,
      "id_professor":profplaning.value.id_professor,
      "id_module":profplaning.value.id_module,
      "groupe":profplaning.value.groupe,
      "jour":jour.value,
      "creneau":creneau.value,
      "salle":salleSignal.value,
      "annee_univ":yearSignal+"/"+(yearSignal+1),
      "date_absence":dateAbsenceSignal.value
    },
    {
      headers: {
        'auth-token': authInfo.token
        }
    }).then(rep => {
     // getYearUniv();// dans cette fonction il y a aussi la fonction de récupération des plannings
      console.log("******************************************************");
      console.log(rep.data);
      if(rep.data.success){
        setMsg("<b>Rattrapage bien programmé</b>");
        setClasse("text-success");
        
      }else{
        
            if(rep.data.planning1.length > 0){
              setMsg("Impossible d'ajouter ce rattrapage!<br/> Créneau attribué à "+rep.data.planning1[0].prenom_fr+" "+rep.data.planning1[0].nom_fr);
            }
            if(rep.data.planning2.length > 0){
              setMsg("Impossible d'ajouter ce rattrapage!<br/>  Cet enseignat(e) n'est pas disponible dans cet créneau, il (elle) assure le cours "+rep.data.planning2[0].module_name+" dans la salle: "+rep.data.planning2[0].salle);
            }
            if(rep.data.is_chevauchement){
              let actualSem = rep.data.actualSem;
              let chev = rep.data.overlapedWith[0];
              
              setMsg("Impossible d'ajouter ce rattrapage!<br/>  Chevauchement entre les semestres: <br> module de sem:"+actualSem+"<br> chevauché avec sem:"+chev);
            }
          
            setClasse("text-danger");
      }
      getAllRattrapagesByYear(yearSignal.value);
      setShow(true);
    
    });
  }

  //==========================Delete rattrapage========================
const deleteRattrapage = async (id) => {
  api.delete('/api/deleterattrapage/' +id,{
    headers: {
      'auth-token': authInfo.token
      }
  }).then(rep => {
    console.log(rep.data);
    getAllRattrapagesByYear(yearSignal.value);
    
  }).catch(err => {
    console.log(err);
  });
}
//--------------------------------------------------------


    useSignals();  
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Menu/>
    
        <Grid container spacing={2} marginTop={4}>
                <Grid item xs={12}>
                <div className='display-3 text-primary text-center'>Gestion des rattrapages: {yearSignal.value}/{yearSignal.value+1}</div>
                </Grid>
      
      {/**===============================Prof================================================ */}
        <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Prof</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={profId.value}
          label="Prof"
          onChange={e=>{profId.value = e.target.value; filieresOfProf.value = [...new Set(
            listPlannings.value
              .filter(item => item.id_professor === e.target.value) // Filtrer par id_professor
              .map(item => item.libelle_filiere) // Extraire les libelle_filiere
          )];}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {uniquesNames.value.map((prof,i) =><MenuItem value={prof.id_professor} key={i}>{prof.nom_fr} {prof.prenom_fr}</MenuItem>)}
        </Select>
       
      </FormControl>
        
      </Grid>
      {/**===============================Filiere================================================ */}
      <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label2">Filière</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label2"
          value={filierSignal.value}
          label="Créneau"
          onChange={e=>{filierSignal.value = e.target.value; modulesOfProf.value = listPlannings.value.filter(item=>item.libelle_filiere === filierSignal.value).filter(item=>item.id_professor === profId.value);}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {filieresOfProf.value.map((filier,i) =><MenuItem value={filier} key={i}>{filier}</MenuItem>)}
        </Select>
       
      </FormControl>
      </Grid>
      {/**=============================================================================== */}      

{/**===============================Module================================================ */}
<Grid item xs={12}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Module</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={moduleID.value}
          label="Prof"
          onChange={e=>{moduleID.value=e.target.value; profplaning.value = listPlannings.value.find(item => item.id_professor === profId.value && item.id_module === moduleID.value);}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {modulesOfProf.value.map((prof,i) =><MenuItem value={prof.id_module} key={i}>{prof.module_name +" - "+prof.groupe}</MenuItem>)}
        </Select>
       
      </FormControl> 
      </Grid>
       
       {/**---------------------------------information planning ------------- */}
       { (profplaning.value.id_professor != null)&&
        <Grid item xs={12}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
       <ListItem>
         <ListItemAvatar>
           <Avatar>
             <AccessAlarmsIcon />
           </Avatar>
         </ListItemAvatar>
         <ListItemText primary="Créneau" secondary={profplaning.value.jour+":"+profplaning.value.creneau} />
       </ListItem>     
     </List>
         </Grid>
       }
        
       {/**-------------------------------------------------------------------*/}

       <Grid item xs={12}>
       <div className='h2 text-primary text-center'>Programmer un rattrapage</div>
      </Grid>
      {/**--------------------------------Date d'absence--------------------------------- */}
      
      <Grid item xs={6}>
      <div className='form-group'>
                <label className='update_label'>Date d'absence</label>
               <input type='date' value={dateAbsenceSignal.value} onChange={(e)=>{dateAbsenceSignal.value = e.target.value;}} className='form-control modif'/>
          </div>
      </Grid>
      {/**--------------------------------Date de rattrapage--------------------------------- */}
      
      <Grid item xs={6}>
      <div className='form-group'>
                <label className='update_label'>Date de rattrapage</label>
               <input type='date' value={dateRattrapage.value} onChange={(e)=>{dateRattrapage.value = e.target.value; let dateChoisie = new Date(e.target.value); jour.value=jours[dateChoisie.getDay()];}} className='form-control modif'/>
          </div>
      </Grid>
    
 {/**===============================jour================================================ */}
        <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">jour</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={jour.value}
          label="jour"
          disabled
          onChange={e=>jour.value = e.target.value}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {jours.map((j,i) =><MenuItem value={j} key={i}>{j}</MenuItem>)}
        </Select>
       
      </FormControl>
        
      </Grid>
      {/**===============================Créneau================================================ */}
      <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label2">Créneau</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label2"
          value={creneau.value}
          label="Créneau"
          onChange={e=>{creneau.value = e.target.value; getSallesDisp();}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {creneaux.map((j,i) =><MenuItem value={j} key={i}>{j}</MenuItem>)}
        </Select>
       
      </FormControl>
      </Grid>
      {/**===============================Salles================================================ */}
        <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">Salle</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={salleSignal.value}
          label="Salle"
          onChange={e=>{salleSignal.value=e.target.value; }}
        >
          <MenuItem value="">
            <em>Salles disponibles</em>
          </MenuItem>
          {sallesDispo.value.map((salle,i) =><MenuItem value={salle.name_fr} key={i}>{salle.name_fr}</MenuItem>)}
        </Select>
       
      </FormControl> 
      </Grid>
     
     
      {/**=============================================================================== */}      
      <Grid container spacing={2} marginBottom={4}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} marginTop={2}> 
                <Button onClick={addRattrapage} className='col-12'  variant="contained" color="success">
                        Ajouter le rattrapage
                </Button>
            </Grid>
            <Grid item xs={3}></Grid>
    </Grid>
                
        </Grid>
    {/**------------------------------------- */}
    {/**************************affichage de rattrapage**************************** */}
    <Row>
     <Col>
      <hr className='trait'/>
      <h3>Rattrapages</h3>
      </Col>
      <Col md={12}>
      <Button variant="contained" color="success" className='col-3 mb-2' onClick={()=>null} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
      </Col>
     </Row>


         {/************************Affichage table ************/}
     <Grid container spacing={2}>
      <Grid item xs={12}>
      <Table aria-label="striped table" striped>
      <thead>
        <tr>
          <th style={{ width: '10%' }}>Date d'absence</th>
          <th style={{ width: '10%' }}>Date</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Filière</th>
          <th>Module</th>
          <th>SM</th>
          <th>GR</th>
          <th>Jour</th>
          <th style={{ width: '15%' }}>Créneau</th>
          <th style={{ width: '10%' }}>Salle</th>
          <th><FontAwesomeIcon size={fa9} icon={faEdit} /></th>
        </tr>
      </thead>
      <tbody>
        {allRattrapages.value.map(
          rat=>(<tr>
          <td>{rat.date_absence?.split('T')[0]}</td>
          <td>{rat.date_rattrapage?.split('T')[0]}</td>
          <td>{rat.nom_fr}</td>
          <td>{rat.prenom_fr}</td>
          <td>{rat.libelle_filiere}</td>
          <td>{rat.module_name}</td>
          <td>S{rat.id_semestre}</td>
          <td>{rat.groupe}</td>
          <td>{rat.jour}</td>
          <td>{rat.creneau}</td>
          <td>{rat.salle}</td>
          <td><Button onClick={()=>{handleShowDel(rat.id)}} variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button></td>
        </tr>)
        )}
       
        
      </tbody>
    </Table>
      </Grid>
     </Grid>
     {/*****************Fin table***************** */}
    

    {/***********Modal message addRattrapage*********************/}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Rattrapage </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classe}>{ <div dangerouslySetInnerHTML={{ __html: msg }} /> }</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleClose();}}>
            OK
          </Button>
          </Stack>
        </Modal.Footer>
    </Modal>
{/***********Modal delete*********************/}
    <Modal show={showDel} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ce rattrapage?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleCloseDelete}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deleteRattrapage(del);handleCloseDelete(); console.log("id à supprimé:",del)}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
      

      {/*----------------------------------------------------*/}
        </Box>
      )
}

export default AddRattrapage